<template>
<div class="select-local">
    <select v-model="province" @change="setProv">
        <option v-for="(item,index) in Provinces" :key="index+'_'+item.Province_ID" :value="item.Province_Name"> {{item.Province_Name}} </option>
    </select>
    <select v-model="city" @change="setCity">
        <option v-for="(item,index) in Citys" :key="index+'_'+item.City_ID" :value="item.City_Name"> {{item.City_Name}} </option>
    </select>
    <select v-model="area" @change="setArea">
        <option v-for="(item,index) in Areas" :key="index+'_'+item.Area_ID" :value="item.Area_Name"> {{item.Area_Name}} </option>
    </select>
</div>
</template>

<script>
export default {
    data() {
        return {
            Provinces: [{
                "Province_Name": '请选择',
                "Province_ID": 0
            }],
            Citys: [{
                "City_Name": '请选择',
                "City_ID": 0
            }],
            Areas: [{
                "Area_Name": '请选择',
                "Area_ID": 0
            }],
            province: "请选择",
            city: "请选择",
            area: "请选择"
        }
    },
    mounted() {
        this.getProvincesList()
    },
    methods: {
        getProvincesList() {
            fetch('/Ajax/Word/getprovincelist')
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    this.Provinces = [{
                        "Province_Name": '请选择',
                        "Province_ID": 0
                    }].concat(res.list)

                })
        },
        getCitysList(Province_ID) {
            fetch('/Ajax/Word/getcitylist?province=' + Province_ID, {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    this.Citys = [{
                        "City_Name": '请选择',
                        "City_ID": 0
                    }].concat(res.list)
                })
        },
        getAreasList(City_ID) {
            fetch('/Ajax/Word/getarealist?city=' + City_ID, {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    this.Areas = [{
                        "Area_Name": '请选择',
                        "Area_ID": 0
                    }].concat(res.list)
                })
        },
        setProv() {
            // 置空
            this.Citys = [{
                "City_Name": '请选择',
                "City_ID": 0
            }]
            this.Areas = [{
                "Area_Name": '请选择',
                "Area_ID": 0
            }]
            this.city = '请选择'
            this.area = '请选择'

            let nowProvince = this.Provinces.filter(item => item.Province_Name == this.province)
            this.getCitysList(nowProvince[0].Province_ID)
        },
        setCity() {
            // 置空
            this.Areas = [{
                "Area_Name": '请选择',
                "Area_ID": 0
            }]
            this.area = '请选择'

            let nowCity = this.Citys.filter(item => item.City_Name == this.city)
            this.getAreasList(nowCity[0].City_ID)
        },
        setArea() {
            console.log("area", this.area)
            this.$emit("setLocal",[this.province,this.city,this.area].join(" "))
        },
    }
}
</script>

<style scoped>
.select-local {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.select-local select {
    width: 31%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.select-local option,.select-local select{
    border: none;
    outline: none;
    background:none;
    font-size: 14px;
    color:#333;
    border: 1px solid #ccc
}
</style>
