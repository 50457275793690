<template>
        <h5>推荐</h5>
        <div class="actives-outer">
            <div class="actives-wrap last-actives-wrap">
                <div class="actives-item last-actives-item" v-for="(item,index) in list" :key="index">
                    <router-link :to="'/productDetails/'+item.Product_ID">
                        <div class="actives-img-wrap">
                            <img v-if="item.Product_ImageUrl.length>0" :src="domin + item.Product_ImageUrl" alt="active_imgs">
                            <img v-else :src="standImg" alt="active_imgs">
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                    <span class="actives-statu" :class="{'on':computeStatu(item.Product_FlashStart,item.Product_FlashEnd)=='进行中'}">{{computeStatu(item.Product_FlashStart,item.Product_FlashEnd)}}</span>
                </div>
            </div>
        </div>
</template>

<script>
    import standImg from "@/assets/standImg.jpg"
    export default {
        props: {
            list:{
                type: Array,
                required: true,
                default: [],
            },
            
        },
        data(){
            return{
                 standImg:standImg
            }
        },
        methods:{
            computeStatu(start,end){
            let statuText= "";
            let starts = start.replace(/-/g,"/")
            let ends = end.replace(/-/g,"/")
            let stime = new Date(starts).getTime()
            let etime = new Date(ends).getTime()
            let now = Date.now()

            if(now < stime){
                statuText = "未开始";
            }
            else if(now > stime && now < etime){
                statuText = "进行中";

            }else if(now > etime){
                statuText = "已结束";

            }else{
                statuText = "未知";
            }


            return statuText
        },
        }
    }
</script>

<style scoped>
h5{
    padding: 12px;
    margin: 0;
}
.actives-outer {
    overflow: hidden;
    overflow-x: scroll;
}

.actives-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.actives-item {
    flex: none;
    width: 60vw;
    margin: 6px;
    padding: 4px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.actives-item h6 {
    margin: 0;
    padding: 5px 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.last-actives-wrap {
    flex-wrap: wrap;
}

.last-actives-item {
    box-sizing: border-box;
    width: calc(50vw - 12px);
}
.actives-img-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    height: 30vw;
}

.actives-img-wrap img {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
}

.last-actives-wrap .last-actives-item:first-child {
    width: calc(100% - 12px);
}

.last-actives-wrap .last-actives-item:first-child .actives-img-wrap {
    height: 36vw;
}

</style>