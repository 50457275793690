<template>
<div>

    <div class="sign-wrap" v-show="showSign">
        <div class="signa">
            <div class="btn">
                <a class="cancel-btn" href="javascript:void(0);" @click="clear">
                    重写
                </a>
                <a class="save-btn" href="javascript:void(0);" @click="save">
                    保存
                </a>
            </div>
            <canvas id="designature" class="canvas" :width="cwidth" :height="cheight" @touchstart="starts" @touchmove="moves" @touchend="end"></canvas>
        </div>
    </div>

    <div class="sign-img">
        <div class="img-wrp" @click="showSign=true">
            <img v-if="signImg.length>0" :src="domin+signImg" alt="">
            <p v-else class="sign-show">点我签名</p>
        </div>
        <!-- <a class="sign-btn" href="javascript:void(0);">点我签名</a> -->

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            phone: "",
            signImg: "",
            resultUrl: '',
            dom: null,
            line: [],
            cwidth: 0,
            cheight: 0,
            offsetLeft: 0,
            offsetTop: 0,
            width: '0px',
            height: '0px',
            radius: 0,
            touchDo: false,
            showSign: false
        }
    },
    created() {
        // console.log(window.innerWidth, window.innerHeight)
        this.width = window.innerWidth - 50 + 'px';
        this.height = window.innerHeight - 15 + 'px';
        this.cwidth = window.innerWidth - 50;
        this.cheight = window.innerHeight - 15;

    },
    mounted() {

        var ocanvas = document.getElementById('designature');
        this.dom = ocanvas.getContext('2d');
        console.log(ocanvas.offsetLeft, ocanvas.offsetTop)
        this.offsetLeft = 45;
        this.offsetTop = 7.5;

    },
    methods: {
        save() {
            var that = this;
            if (!this.touchDo) {
                alert("请先签字完，再保存！")
                return false
            }

            var ocanvas = document.getElementById('designature')
            var imgSign = ocanvas.toBlob(function (blob) {

                var file = new File([blob], 'sign.png', {
                    type: 'image/png'
                });

                ajxImg("/Ajax/upload_json.ashx?dir=image", file, function (res) {
                    console.log(res)
                    that.signImg = res.url
                    that.$parent.imagesign = res.url
                    that.showSign = false

                })
            })

        },
        clear() {
            this.touchDo = false
            this.dom.clearRect(0, 0, 1000, 1000)
        },

        end(e) {

        },
        distance(a, b) {
            let x = b.x - a.x;
            let y = b.y - a.y;
            return Math.sqrt(x * x + y * y);
        },
        starts(e) {
            // console.log(e)
            this.line.push({
                points: [{
                    time: new Date().getTime(),
                    x: e.touches[0].clientX - this.offsetLeft,
                    y: e.touches[0].clientY - this.offsetTop,
                    dis: 0
                }]
            })
            let currentPoint = {
                x: e.touches[0].clientX - this.offsetLeft,
                y: e.touches[0].clientY - this.offsetTop
            }
            this.currentPoint = currentPoint
            this.drawer(this.line[this.line.length - 1])
        },
        moves(e) {
            // console.log(e)
            this.touchDo = true
            let point = {
                x: e.touches[0].clientX - this.offsetLeft,
                y: e.touches[0].clientY - this.offsetTop
            }
            this.lastPoint = this.currentPoint,
                this.currentPoint = point
            this.line[this.line.length - 1].points.push({
                time: new Date().getTime(),
                x: e.touches[0].clientX - this.offsetLeft,
                y: e.touches[0].clientY - this.offsetTop,
                dis: this.distance(this.currentPoint, this.lastPoint)
            })
            this.drawer(this.line[this.line.length - 1])
        },
        drawer(item) {
            let x1, x2, y1, y2, len, radius, r, cx, cy, t = 0.5,
                x, y;
            var time = 0;
            if (item.points.length > 2) {
                let lines = item.points[item.points.length - 3];
                let line = item.points[item.points.length - 2];
                let end = item.points[item.points.length - 1];
                x = line.x;
                y = line.y;
                x1 = lines.x;
                y1 = lines.y;
                x2 = end.x;
                y2 = end.y;
                var dis = 0;
                time = (line.time - lines.time) + (end.time - line.time)
                dis = line.dis + lines.dis + end.dis;
                var dom = this.dom;
                var or = Math.min(time / dis * this.linePressure + this.lineMin, this.lineMax);
                cx = (x - (Math.pow(1 - t, 2) * x1) - Math.pow(t, 2) * x2) / (2 * t * (1 - t))
                cy = (y - (Math.pow(1 - t, 2) * y1) - Math.pow(t, 2) * y2) / (2 * t * (1 - t))
                dom.lineCap = 'round'
                dom.strokeStyle = 'black'
                dom.lineWidth = 5
                dom.beginPath();
                dom.moveTo(x1, y1);
                dom.quadraticCurveTo(cx, cy, x2, y2);
                dom.stroke();
            }

        },
    }
}
</script>

<style scoped>
.signa {
    position: relative;
    overflow: hidden;
    background-color: #fbfbfb;
    height: 100vh;
    width: 100vw;
}

.canvas {
    background-color: #fff;
    position: absolute;
    z-index: 999;
    top: 7.5px;
    left: 45px;
    border: 1px solid #d6d6d6;
}

.btn {
    width: 97vh;
    height: 35px;
    left: 38px;
    top: 1.5vh;
    position: fixed;
    background-color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    transform: rotateZ(90deg);
    transform-origin:  top left;
}

.cancel-btn,
.save-btn {
    border: 1px solid #a9a1a1;
    color: #666;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 3px;
    width: 49%;
    text-decoration: none;

}

.save-btn {
    background: #A91F52;
    border: 1px solid #A91F52;
    color: #fff;

}
.sign-show{
    color: #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sign-btn {
    text-decoration: none;
    color: #fff;
    border-radius: 6px;
    line-height: 37px;
    background: #f97a0a;
    display: block;
    text-align: center;
    margin: 20px 12px;
    box-sizing: border-box;
}

.img-wrp {
    position: relative;
    margin: 12px;
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 8px;
    min-height: 120px;
    background-color: rgba(255, 255, 255, 0.5);
}

.img-wrp img {
    height: 200px;
    transform: rotateZ(-90deg);
}

.sign-wrap {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
}
</style>
