<template>
    <div class="msg-cont" :style="{display:blk}">
        <div class="msg-main" v-html="msg"></div>
        <a href="javascript:void(0);" class="agree-btn" @click="closeNow">同意以上协议并签字购买</a>        
    </div>
</template>

<script>
    export default {
        props: {
            msg:{
                type: String,
                required: true,
                default: "此处为信息提示框内容",
            },
            blk:{
                type: String,
                required: true,
                default: "none",
            },
        },
        methods: {
            closeNow(){
                this.$emit("closeNow");
            }
        },
    }
</script>

<style scoped>
.agree-btn{
    display: block;
    width: 97%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background-color: #ff563f;
    border-radius: 6px;
    margin-top: 6px;
    margin-left: 1.5%;
}
.msg-cont{
    display:none;
    transition: all 300ms ease;    
    z-index: 99;
    position: fixed;
    top: 12px;
    bottom: 92px;
    left: 12px;
    right: 12px;
    overflow: hidden;
    overflow-y: auto;
}
.msg-main{
    line-height: 1.6;
    text-align: justify;
    font-size: 13px;
    padding: 6px ;
    color: #333;
    border-radius: 6px;
    background-color:rgba(255,255,255, 0.85);
    backdrop-filter: blur(10px);
}
</style>