<template>
    <div>
        <div class="tblup">
            <div class="glotbl spcwrp">
                <div class="tbTit">联系信息</div>
                <div class="tbInnr">
                    <label for>{{Product_FlashSale == 1?'姓名':'学员姓名'}}</label>
                    <input
                        type="text"
                        :placeholder="Product_FlashSale == 1?'请填写姓名':'请填写学员姓名'"
                        v-model="name"
                    />
                    <span class="span_dian" @click="showCandain('name')">...</span>
                </div>
                <div class="tbInnr" v-if="Product_HasCertno == 1">
                    <label for>学员身份证</label>
                    <input type="text" placeholder="请填写学员身份证号码" v-model="certno" />
                    <span class="span_dian" @click="showCandain('certno')">...</span>
                </div>
                <div class="tbInnr" v-if="Product_HasSchool == 1">
                    <label>所在学校</label>
                    <input type="text" placeholder="请填写学员学校名" v-model="name_school" />
                </div>
                <div class="tbInnr" v-if="Product_Merchant!=13">
                    <label>{{Product_FlashSale == 1?'所在社区':'所在年级'}}</label>
                    <input
                        type="text"
                        :placeholder="Product_FlashSale == 1?'请填写学员所在社区':'请填写学员年级段'"
                        v-model="name_grade"
                    />
                </div>
                <!-- <div class="tbInnr">
                <label>家长姓名</label>
                <input type="text" placeholder="请填写学员家长姓名" v-model="name_parent">
            </div>
            <div class="tbInnr">
                <label>单位/组织</label>
                <input type="text" placeholder="所在防疫志愿队或单位" v-model="name_company">
                </div>-->
                <!-- 学员姓名，年级，家长姓名、参与疫情防控的志愿服务组织或者所在单位 -->
                <div class="tbInnr">
                    <label for>手机</label>
                    <input type="text" placeholder="请填写家长手机号码" v-model="phone" />
                    <span class="span_dian" @click="showCandain('phone')">...</span>
                </div>
                <div class="tbInnr" v-if="model.Product_Other1&&model.Product_Other1.length > 0">
                    <label>{{model.Product_Other1}}</label>
                    <input
                        type="text"
                        :placeholder="'请填写学员的'+model.Product_Other1"
                        v-model="other1"
                    />
                </div>
                <div class="tbInnr" v-if="model.Product_Other2&&model.Product_Other2.length > 0">
                    <label>{{model.Product_Other2}}</label>
                    <input
                        type="text"
                        :placeholder="'请填写学员的'+model.Product_Other2"
                        v-model="other2"
                    />
                </div>
                <div class="tbInnr" v-if="model.Product_Other3&&model.Product_Other3.length > 0">
                    <label>{{model.Product_Other3}}</label>
                    <input
                        type="text"
                        :placeholder="'请填写学员的'+model.Product_Other3"
                        v-model="other3"
                    />
                </div>
            </div>
            <!-- <div class="glotbl">
            <div class="tbTit">
                地址
            </div>
            <div class="tbInnr specilWrp">
                <label style="margin-right: 1rem;">区域</label>
                <SelectCity @setLocal="getLocalData" />
            </div>
            <div class="tbInnr">
                <label for="">详细地址</label>
                <input style="padding-left: 1rem;" type="text" placeholder="请填写居住地或详细地址" v-model="address">
                <span class="span_dian" @click="showCandain('address')">...</span>
            </div>
            </div>-->
            <!-- 		<div class="swichwrp">

<text>是否设为默认地址</text>

<switch color="#F97A0A" checked="checked"></switch>

            </div>-->
        </div>
        <div class="tblup">
            <div class="glotbl">
                <div class="totalWrp">
                    <img :src="item.image?domin+item.image.Images_Url:'../assets/standImg.jpg'" />
                    <div class="tbTits">
                        <div class="tb-mintit">{{item.name}}</div>
                        <div class="tb-mod">
                            {{item.stock.Stock_Color}} {{item.stock.Stock_Size}}
                            {{item.stock.Stock_Variety}}
                        </div>
                    </div>
                    <label>x {{item.count}}</label>
                    <label>¥{{item.sellPrice}}</label>
                </div>
                <div class="tbInnr tbCount">
                    <label>配送费</label>
                    <label>¥{{item.Freight}}</label>
                </div>
            </div>
            <div class="glotbl">
                <div class="coupons">
                    <div class="tit">
                        <span>可用券</span>
                    </div>
                    <div class="coupon-list" v-if="couponList.length > 0">
                        <div class="coupon-item" v-for="(item,index) in couponList" :key="index">
                            <div class="coupon-name">
                                <img src="../assets/coupon_icon.png" />
                                <span>{{item.Coupon_Name}}</span>
                            </div>
                            <div class="coupon-price">
                                减 &yen;
                                <span>{{item.Coupon_Money}}</span>
                            </div>
                            <input type="checkbox"
                                :checked="couponcheckList.indexOf(item.Coupon_ID) >= 0?true:false"
                                @click="checkCoupon(item)"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <span class="no-coupon">无可使用红包</span>
                    </div>
                </div>
            </div>
            <div class="glotbl">
                <div class="tbInnr tbCount">
                    <div></div>
                    <div>
                        <span class="ans">合计</span>
                        <span> ¥ {{total}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="active-tips" v-html="actTip"></div>
        <SignName v-if="Product_Merchant!=13" />

        <div class="Btmwrp">
            <label>¥{{total}}</label>
            <a herf="javascript:void(0);" @click="sendOrder">去支付</a>
        </div>
        <Candia v-show="show" :mdata="current_Arr" @closeDain="closeCandia" @setVal="setCandia" />
        <Recommd v-if="recommdList&&recommdList.length > 0" :list="recommdList"></Recommd>
        <notice-content :msg="contract" :blk="blk" @closeNow="closeNow" />
    </div>
</template>

<script>
import SelectCity from "@/components/SelectCity";
import Candia from "@/components/Candia";
import Recommd from "@/components/Recommd";
import SignName from "@/components/SignName";
import NoticeContent from '@/components/NoticeContent.vue';

export default {
    data() {
        return {
            sended: true,
            domin: this.domin,
            model: {},
            other1: "",
            other2: "",
            other3: "",
            imagesign: "",
            ordersid: 0,
            name: "",
            certno: "",
            Product_HasCertno: 0,
            Product_Merchant: 0,
            Product_ID: 0,
            Product_FlashSale: 0,
            name_parent: "",
            name_company: "",
            name_grade: "",
            name_school: "",
            phone: "",
            item: {},
            mid: 0,
            address: "",
            detailsAdress: "",
            price: 0,
            total: 0,
            Merchant_Name: "",
            Merchant_DeliveryFee: 0,
            order_array: [],
            orderModel: "",
            adrsvalue: ["省份", "城市", "区县"],
            cityPickerValueDefault: [10, 2, 0],
            pickerText: "",
            district: "",
            ordersid: 0,
            addressArr: [],
            recentInData: [],
            address_Arr: [],
            name_Arr: [],
            phone_Arr: [],
            certno_Arr: [],
            current_Arr: [],
            current_Type: "",
            recommdList: [],
            actTip: "",
            show: false,
            couponList: [],
            couponcheckList:[],
            blk:'none',
            agreement:false,
            contract:'',
        };
    },
    mounted() {
        this.getRecentInData();
        this.getActTip();
        this.getCoupons();
        // 如果存在活动产品购买协议,购买前需提示用户
        if(this.Product_Instruction.length>10){
            this.contract = this.Product_Instruction;
        }
    },
    components: {
        SelectCity,
        Candia,
        Recommd,
        SignName,
        NoticeContent,
    },
    created() {
        console.log("item:", JSON.parse(localStorage.getItem("itemBuy")));

        this.item = JSON.parse(localStorage.getItem("itemBuy"));
        this.price = this.item.count * this.item.sellPrice;
        this.total = this.price + this.item.Freight;
        this.Product_Merchant = localStorage.getItem("Product_Merchant");
        this.Product_ID = localStorage.getItem("Product_ID");
        this.model = JSON.parse(localStorage.getItem("model"));
        this.Product_FlashSale = localStorage.getItem("Product_FlashSale");
        this.Product_HasCertno = localStorage.getItem("Product_HasCertno");
        this.Product_HasSchool = localStorage.getItem("Product_HasSchool");
        this.Product_Instruction = localStorage.getItem("Product_Instruction");
    },
    methods: {
        closeNow(){
            this.blk = "none";
            this.agreement = true;
        },
        checkCoupon(item) {
            this.couponcheckList.indexOf(item.Coupon_ID) == -1
                ? this.couponcheckList.push(item.Coupon_ID)
                : this.couponcheckList.splice(this.couponcheckList.indexOf(item.Coupon_ID), 1);
                this.total = this.price + this.item.Freight;
                if(this.couponcheckList.length > 0){
                    this.couponcheckList.forEach(item => {
                        this.total -= this.couponList.filter(itemFull=>itemFull.Coupon_ID == item)[0].Coupon_Money;
                    });
                }
            console.log("couponcheckList:", this.couponcheckList);
        },
        getCoupons() {
            this.wpost({
                url: "/Ajax/WordWelfare/getcouponlist",
                data: {
                    productid: this.Product_ID,
                    status: 0,
                    coupontype: 0,
                },
                succ: (res) => {
                    this.couponList = res.list;
                    console.log("coupon list:", res);
                },
            });
        },
        getActTip() {
            this.wpost({
                url: "/Ajax/WordProduct/getproperty",
                succ: (res) => {
                    console.log("act tip", res);
                    this.actTip = res.model.Property_Team;
                },
            });
        },
        showCandain(type) {
            this.show = true;
            this.current_Arr = this[type + "_Arr"];
            this.current_Type = type;
            console.log("current_Arr", this.current_Arr);
        },
        closeCandia() {
            this.show = false;
        },
        setCandia(val) {
            this[this.current_Type] = val;
            console.log("val", val);
        },
        getLocalData(data) {
            console.log("local name", data);
            this.district = data;
        },
        candidates(typ) {
            if (typ == "name") {
                return this.recentInData.map((item) => {
                    if (item.Orders_Receiver.split("_").length > 1) {
                        return item.Orders_Receiver.split("_")[0];
                    } else {
                        return item.Orders_Receiver;
                    }
                });
            } else if (typ == "address") {
                return this.recentInData.map((item) => {
                    return item.Orders_Address;
                });
            } else if (typ == "phone") {
                return this.recentInData.map((item) => {
                    return item.Orders_Phone;
                });
            } else if (typ == "certno") {
                return this.recentInData.map((item) => {
                    if (!!item.Orders_CertNo) {
                        return item.Orders_CertNo;
                    }
                });
            }
        },
        getRecentInData() {
            this.wpost({
                url: "/Ajax/WordWelfare/getorderslist",
                succ: (res) => {
                    console.log("getRecentInData", res);
                    this.recentInData = res.list;

                    this.name_Arr = Array.from(
                        new Set(
                            this.candidates("name").filter(
                                (item) => !this.isNil(item)
                            )
                        )
                    );
                    this.phone_Arr = Array.from(
                        new Set(
                            this.candidates("phone").filter(
                                (item) => !this.isNil(item)
                            )
                        )
                    );
                    this.address_Arr = Array.from(
                        new Set(
                            this.candidates("address").filter(
                                (item) => !this.isNil(item)
                            )
                        )
                    );
                    this.certno_Arr = Array.from(
                        new Set(
                            this.candidates("certno").filter(
                                (item) => !this.isNil(item)
                            )
                        )
                    );
                },
            });
        },
        sendOrder() {
            
            if (this.name == "") {
                let msg =
                    this.Product_FlashSale == 1
                        ? "请填写姓名"
                        : "请填写学员姓名";
                alert(msg);
                return false;
            }
            if (this.imagesign == "" && this.Product_Merchant != 13) {
                alert("请先签名");
                return false;
            }
            if (this.name_grade == "" && this.Product_Merchant != 13) {
                let msg =
                    this.Product_FlashSale == 1
                        ? "请填写所在社区"
                        : "请填写学员的年级";
                alert(msg);
                return false;
            }

            if (this.Product_HasSchool == 1 && this.name_school == "") {
                alert("请填写学员的学校");
                return false;
            }
            if (this.Product_HasCertno == 1 && this.certno == "") {
                alert("请填写学员身份证号码");
                return false;
            }

            if (this.model.Product_Other1.length > 0 && this.other1 == "") {
                alert("请填写学员" + this.model.Product_Other1);
                return false;
            }
            if (this.model.Product_Other2.length > 0 && this.other2 == "") {
                alert("请填写学员" + this.model.Product_Other2);
                return false;
            }
            if (this.model.Product_Other3.length > 0 && this.other3 == "") {
                alert("请填写学员" + this.model.Product_Other3);
                return false;
            }

            // if (this.name_company == '') {
            //     alert('请填写所在防疫志愿队或单位')
            //     return false
            // }
            if (this.phone == "" || this.phone.length != 11) {
                alert("请填写收件人手机号码");
                return false;
            }
            // if (this.district == '') {
            //     alert('请选择收件人的省市区')
            //     return false
            // }
            // if (this.address == '') {
            //     alert('请填写收件人的地址')
            //     return false
            // }
            if(this.Product_Instruction.length > 10 && this.agreement == false){
                this.blk = "block";
                alert("请先阅读并同意协议");
                return false;
            }

            if (this.sended == false) {
                return false;
            }

            // 防止多次点击支付下单按钮
            this.sended = false;
            let postData = {
                stockid: this.item.stockid,
                count: this.item.count,
                certno: this.certno,
                imagesign: this.imagesign,
                name: [
                    this.name,
                    this.name_grade,
                    this.name_parent,
                    this.name_company,
                    this.name_school,
                ].join("_"),
                phone: this.phone,
                address: this.district + this.address,
                
            };

            if(this.couponcheckList.length>0){
                postData.couponlist = this.couponcheckList.join(",")
            }

            if (this.other1 != "") {
                postData.other1 = this.other1;
            }

            if (this.other2 != "") {
                postData.other2 = this.other2;
            }

            if (this.other3 != "") {
                postData.other3 = this.other3;
            }

            this.wpost({
                url: "/Ajax/WordWelfare/createordersdirectbuy",
                data: postData,
                fail: (res) => {
                    this.sended = true;
                    console.log("进入了fail", res.data.productlist);
                    this.recommdList = res.data.productlist;
                },
                succ: (data) => {
                    this.sended = true;
                    console.log("订单:", data);
                    this.ordersid = data.ordersid;

                    if (data.ordersstatus == 2) {
                        alert("报名成功，谢谢参与");
                        this.$router.push(
                            "/orderDetails/" + this.ordersid + "/0"
                        );

                        return;
                    }

                    // 发起支付

                    this.wpost({
                        url: "/Ajax/WordWelfare/getwxpaydata",
                        data: {
                            ordersid: this.ordersid,
                            contactname: [
                                this.name,
                                this.name_grade,
                                this.name_grade,
                                this.name_company,
                            ].join("_"),
                            contactphone: this.phone,
                            contactprovince: this.addressArr[0],
                            contactcity: this.addressArr[1],
                            contactarea: this.addressArr[2],
                            contactaddress: this.address,
                        },
                        succ: (data) => {
                            WeixinJSBridge.invoke(
                                "getBrandWCPayRequest",
                                {
                                    appId: data.wxdata.appId, //公众号名称，由商户传入
                                    timeStamp: data.wxdata.timeStamp, //时间戳，自1970年以来的秒数
                                    nonceStr: data.wxdata.nonceStr, //随机串
                                    package: data.wxdata.package,
                                    signType: data.wxdata.signType, //微信签名方式：
                                    paySign: data.wxdata.paySign, //微信签名
                                },
                                (res) => {
                                    if (
                                        res.err_msg ==
                                        "get_brand_wcpay_request:ok"
                                    ) {
                                        alert("支付成功!");
                                        // 如果是优惠券产品, 就跳转到优惠券列表
                                        if (this.Product_ID == 267) {
                                            this.$router.push("/couponlist/1");
                                        } else {
                                            this.$router.push(
                                                "/orderDetails/" +
                                                    this.ordersid +
                                                    "/1"
                                            );
                                        }
                                    } else {
                                        alert("支付失败!");
                                    }
                                }
                            );
                        },
                    });
                },
            });
        },
    },
};
</script>

<style>
page {
    padding-bottom: 70px;
}
.coupons {
    padding: 12px;
    margin: 12px 0;
}
.no-coupon {
    color: #999;
    font-size: 13px;
}

.coupon-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    font-size: 13px;
    background: #ffeff2;
    color: #fa022b;
    margin: 6px 0;
}
.coupon-item img {
    width: 27px;
    padding-right: 5px;
}
.coupon-price span {
    font-size: 16px;
    font-weight: bolder;
}
.coupon-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: bold;
}
.coupons .tit {
    font-size: 13px;
    font-weight: bold;
}
.active-tips {
    padding: 12px;
    margin: 12px 0;
}
.glotbl {
    background-color: #fff;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp img {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.swichwrp switch {
    scale: 0.8;
}

.swichwrp {
    padding: 5px 0;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #ccc;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #f97a0a;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 16px;
    line-height: 2.1;
    box-sizing: border-box;
}

picker {
    width: 100%;
    font-size: 15px;
    padding-left: 1rem;
    height: 43px;
    box-sizing: border-box;
}

picker .uni-input {
    height: 43px;
    line-height: 43px;
}

.uni-input {
    height: 25px;
    line-height: 25px;
}

.tbInnr textarea {
    width: 100%;
    margin: 8px 0;
    border-radius: 5px;
    padding: 6px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.7;
}

.adrsWrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
}

.adrsWrp text {
    width: 31%;
}

.tb-mod {
    white-space: normal;
    font-size: 12px;
    margin-top: 5px;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tb-mintit {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;
}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
}

.tbftp {
    align-items: flex-start;
}

.Btmwrp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 89;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #333;
}

.Btmwrp label {
    margin-left: 12px;
    font-size: 30px;
}

.Btmwrp a {
    color: #fff;
    display: inline-block;
    background-color: #f97a0a;
    margin: 0;
    height: 47px;
    line-height: 47px;
    padding: 0 22px;
    border-radius: 22px;
    text-align: center;
    font-size: 15px;
}
</style>
